export const patientFinding = [
  {
    title: "Allmänt",
    value: "allmänt",
  },
  { title: "Undersokning", value: "undersokning" },
  { title: "Aorta", value: "aorta" },
  { title: "Mitralis", value: "mitralis" },
  { title: "Pulmonalis", value: "pulmonalis" },
  { title: "Tricuspidalis", value: "tricuspidalis" },
  { title: "Regionaliter", value: "regionaliter" },
  { title: "Ovrigt", value: "ovrigt" },
];
